export const useRoom = () => {
  const { $JsonRaw }: any = useNuxtApp()

  const roomShow = useState<any>('roomShow', () => false)
  const setRoomShow = (t: boolean) => {
    roomShow.value = t || false
  }

  return {
    roomShow,
    setRoomShow
  }
}
